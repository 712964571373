import React from "react";
import Icon from "../../atoms/Icon/Icon";

const BlueRectangle = () => (
    <Icon>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13">
            <path
                data-name="Rechteck Legende blau"
                style={{fill: "#32b5ba"}}
                d="M0 0h15v13H0z"/>
        </svg>
    </Icon>
);

export default BlueRectangle;