import React from "react";

import * as styles from "./Caption.module.scss";
import classNames from "classnames";

type propsType = {
    children: React.ReactNode
    className?: string
}

const Caption = (props: propsType) => {

    return (
        <div className={classNames(styles.root, props.className)}>
            {props.children}
        </div>
    );
}

export default Caption;