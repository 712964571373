import React from "react";

import * as styles from "./ForthWidthCell.module.scss";
import classNames from "classnames";

type propsType = {
    children: React.ReactNode
    expandForMobile?: boolean
    expandForSmallOnly?: boolean
}

const ForthWidthCell = (props: propsType) => {

    let rootClasses = classNames(
        styles.root,
        {[styles.expandForMobile]: props.expandForMobile},
        {[styles.expandForSmallOnly]: props.expandForSmallOnly}
    );

    return (<div className={rootClasses}>
        {props.children}
    </div>);
};

export default ForthWidthCell;