import * as React from "react"
import {graphql} from "gatsby";
import {I18nContext, useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";
import Button from "../components/atoms/Button/Button";
import PageReferencesTitle from "../components/atoms/PageReferencesTitle/PageReferencesTitle";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";
import StageTeaser from "../components/molecules/StageTeaser/StageTeaser";
import Text from "../components/atoms/Text/Text";
import List from "../components/molecules/List/List";
import Image from "../components/atoms/Image/Image";
import GridContainer from "../components/grid/GridContainer";
import ForthWidthCell from "../components/grid/ForthWidthCell";
import Caption from "../components/atoms/Caption/Caption";
import BlueRectangle from "../components/icons/BlueRectangle/BlueRectangle";
import DarkBlueRectangle from "../components/icons/DarkBlueRectangle/DarkBlueRectangle";
import TranslatedLink from "../components/util/TranslatedLink";
import Seo from "../components/global/Seo/Seo";

type propType = {
	data: any
	classes: any,
	context: any
}
const TreatmentPage = ({data}: propType) => {
	const {t} = useTranslation();


	const context = React.useContext(I18nContext);
	const currentLanguage = context.i18n.language;

	return (
		<>
			<Seo title={t('treatment:seo.title')} description={t('treatment:seo.description')} />
			<FullWidthContainer>
				<StageTeaser image={data.stageTeaser} imageAlt={t('treatment:imageAlt')} />

				<h1>{t('treatment:mainHeadline')}</h1>

				<h2><Text text={t('treatment:sectionGoals.headline')}/></h2>
				<List list={t('treatment:sectionGoals.list', {returnObjects: true})} />
			</FullWidthContainer>
			<ColoredBox>
				<FullWidthContainer>
					<p className="margin-top-0 margin-bottom-0">
						<Text text={'treatment:sectionSwissTherapy.introText'}/>
					</p>
					<h3>{t('treatment:sectionSwissTherapy.subsectionEet.headline')}</h3>
					<p>
						<Text text={'treatment:sectionSwissTherapy.subsectionEet.copy1'}/>
					</p>
					<p>
						<Text text={'treatment:sectionSwissTherapy.subsectionEet.copy2'}/>
					</p>
					<h3>{t('treatment:sectionSwissTherapy.subsectionSrt.headline')}</h3>
					<p>
						<Text text={'treatment:sectionSwissTherapy.subsectionSrt.copy1'}/>
					</p>
				</FullWidthContainer>
			</ColoredBox>
			<GridContainer>
				<ForthWidthCell expandForSmallOnly={true}>
					<Image imagePath={"/assets/images/treatment/" + currentLanguage + "/glucosylceramide-1.svg"} imageAlt={t('treatment:sectionFinalText.imageAltText1')} />
				</ForthWidthCell>
				<ForthWidthCell expandForSmallOnly={true}>
					<Image imagePath={"/assets/images/treatment/" + currentLanguage + "/glucosylceramide-2.svg"} imageAlt={t('treatment:sectionFinalText.imageAltText2')} />
				</ForthWidthCell>
				<ForthWidthCell expandForSmallOnly={true}>
					<Image imagePath={"/assets/images/treatment/" + currentLanguage + "/glucosylceramide-3.svg"} imageAlt={t('treatment:sectionFinalText.imageAltText3')} />
				</ForthWidthCell>
				<ForthWidthCell expandForSmallOnly={true}>
					<Image imagePath={"/assets/images/treatment/" + currentLanguage + "/glucosylceramide-4.svg"} imageAlt={t('treatment:sectionFinalText.imageAltText4')} />
				</ForthWidthCell>
			</GridContainer>
			<FullWidthContainer className="margin-top-1">
				<Caption>
					<Text text={'treatment:sectionFinalText.imageCaption1'} />
					<DarkBlueRectangle />
					<Text text={'treatment:sectionFinalText.imageCaption2'} />
					<BlueRectangle />
					<Text text={'treatment:sectionFinalText.imageCaption3'} />
				</Caption>
			</FullWidthContainer>
			<FullWidthContainer className="margin-top-2--desktop margin-top-3--mobile">
				<p className="margin-bottom-large">
					<Text text={'treatment:sectionFinalText.copy1'}/>
				</p>
			</FullWidthContainer>
			<FullWidthContainer>
				<TranslatedLink title={t('orderButton.title')} to="/order">
					<Button uppercase={true} inverted={true}>
						{t('treatment:sectionReferences.buttonLabel')}
					</Button>
				</TranslatedLink>
				<PageReferencesTitle>
					<Text text="treatment:sectionReferences.headline" />
				</PageReferencesTitle>
				<PageReferencesText>
					<Text text="treatment:sectionReferences.copy" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="treatment:sectionReferences.explanation" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="approvalCode" />
				</PageReferencesText>
			</FullWidthContainer>
		</>
	)
}

export default TreatmentPage

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "treatment"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
		stageTeaser: file(
			relativePath: { eq: "treatment/StageTeaserDesktop.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
					sizes: "(min-width: 1300px) 1292px, (min-width: 640px) 100vw,  200vw"

				)
			}
		}
	}
`;
